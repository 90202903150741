import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { Check } from '@instech/icons';
import { Table, TableCell } from '@/components/shared/Table/Table';
import { useFleet } from '@/services/fleetsService';
import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ChevronToggle } from '@/components/shared/Chevron';
import { Box } from '@/components/shared/Box';
import { WarWeb } from '@/war';
import { isInternalUser } from '@/components/routing/roles';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { useParams } from 'react-router';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { TableRow } from '../../shared/Table/core/Components';
import { ChangeLogTable } from './ChangeLogTable';

const Column = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 0.5rem;
`;

const BoxContent = styled.div`
  padding: 1rem;
  max-height: 400px;
  overflow: auto;
`;

const Toggle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  cursor: pointer;
`;

const StyledTableCell = styled(TableCell)`
  padding: 1rem 0 1rem 1rem;

  input[type=radio] {
    border: 0px;
    width: 60%;
    height: 1.2rem;
  }
`;

const SectionHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 1rem;
`;

const ncbs = [
  {
    name: 'No Claims Bonus',
    ncbType: 'NoClaimBonus' as WarWeb.NcbPreference,
  },
  {
    name: 'Claims Penalty',
    ncbType: 'ClaimsPenalty' as WarWeb.NcbPreference,
  }
];

const tiers = [
  {
    name: 'Tier A',
    tierType: 'A' as WarWeb.TierType,
  },
  {
    name: 'Tier B',
    tierType: 'B' as WarWeb.TierType,
  },
  {
    name: 'Tier C',
    tierType: 'C' as WarWeb.TierType,
  }
];

const modalOptions = {
  title: 'Fleet settings',
  size: 'small'
};

interface ReadWriteFleetSettingsModalProps extends InlineModalProps {
  callback: (state: WarWeb.UpdateFleetRequest) => Promise<void>;
}

const ReadWriteFleetSettingsModal = ({ callback, closeModal }: ReadWriteFleetSettingsModalProps) => {
  const { clientId, fleetId } = useParams<any>();
  const [tierType, setTierType] = useState<DirtyableValue<WarWeb.TierType>>({});
  const [ncbType, setNcbType] = useState<DirtyableValue<WarWeb.NcbPreference>>({});
  const [open, setOpen] = useState(false);
  const { data } = useFleet(clientId, fleetId);

  useEffect(() => {
    setTierType({ value: data?.tier, isDirty: false });
    setNcbType({ value: data?.ncbPreference, isDirty: false });
  }, [data]);

  const apply = async () => {
    if (tierType || ncbType) {
      await callback({
        fleetId,
        tierType: tierType.isDirty && tierType.value ? tierType.value : undefined,
        ncbPreference: ncbType.isDirty && ncbType.value ? ncbType.value : undefined
      });
      closeModal();
    }
  };

  return (
    <ModalLayout options={modalOptions} closeModal={closeModal}>
      <Column>
        <SectionHeader>Rebates</SectionHeader>
        <Table columns={2} striped layout="1fr 1fr">
          {ncbs.map(n => (
            <TableRow key={n.ncbType} onClick={() => setNcbType({ value: n.ncbType, isDirty: true })}>
              <StyledTableCell>{n.name}</StyledTableCell>
              <StyledTableCell right>
                <input type="radio"
                  name="ncb"
                  value={n.ncbType}
                  checked={ncbType.value === n.ncbType}
                  readOnly
                />
              </StyledTableCell>
            </TableRow>
          ))}
        </Table>
        <p />
        {isInternalUser() && (
          <>
            <SectionHeader>Fleet tier</SectionHeader>
            <Table columns={2} striped layout="1fr 1fr">
              {tiers.map(t => (
                <TableRow key={t.tierType} onClick={() => setTierType({ value: t.tierType, isDirty: true })}>
                  <StyledTableCell>{t.name}</StyledTableCell>
                  <StyledTableCell right>
                    <input type="radio"
                      name="tier"
                      value={t.tierType}
                      checked={tierType.value === t.tierType}
                      readOnly
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </Table>
          </>
        )}
        <p />
        {isInternalUser() && (
          <Box>
            <BoxContent>
              <Toggle onClick={() => setOpen(!open)}>
                Change log
                {' '}
                <ChevronToggle open={open} />
              </Toggle>
              {open &&
                <ChangeLogTable clientId={clientId} fleetId={fleetId} />}
            </BoxContent>
          </Box>
        )}
        <p />
        <ButtonGroup justify="flex-end">
          <Button onClick={closeModal} background={theme.lightGray}>Close</Button>
          <ButtonWithLoader
            onClick={apply}
            disabled={!tierType.isDirty && !ncbType.isDirty}
            background={theme.green}
            icon={(tierType.isDirty || ncbType.isDirty) && <Check />}>
            Apply
          </ButtonWithLoader>
        </ButtonGroup>
      </Column>
    </ModalLayout>
  );
};

const ReadOnlyFleetSettingsModal = ({ closeModal }: InlineModalProps) => {
  const { clientId, fleetId } = useParams<any>();
  const { data } = useFleet(clientId, fleetId);
  return (
    <ModalLayout options={modalOptions} closeModal={closeModal}>
      <Column>
        <SectionHeader>Rebates</SectionHeader>
        <Table columns={2} striped layout="1fr 1fr">
          {ncbs.map(n => (
            <TableRow key={n.ncbType}>
              <StyledTableCell>{n.name}</StyledTableCell>
              <StyledTableCell right>
                <input type="radio"
                  name="ncb"
                  value={n.ncbType}
                  checked={data?.ncbPreference === n.ncbType}
                  readOnly
                />
              </StyledTableCell>
            </TableRow>
          ))}
        </Table>
        <ButtonGroup justify="flex-end">
          <Button onClick={closeModal} background={theme.lightGray}>Close</Button>
        </ButtonGroup>
      </Column>
    </ModalLayout>
  );
};

interface FleetSettingsModalProps extends ReadWriteFleetSettingsModalProps {
  readOnly: boolean;
}
export const FleetSettingsModal = ({ callback, closeModal, readOnly }: FleetSettingsModalProps) => readOnly
  ? <ReadOnlyFleetSettingsModal closeModal={closeModal} />
  : <ReadWriteFleetSettingsModal callback={callback} closeModal={closeModal} />;
