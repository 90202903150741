import { isBroker, isInternalUser } from '@/components/routing/roles';
import { WarWeb } from '@/war';
import useSWR from 'swr';

const basePath = 'me';

export const useMe = ({ cancelLoad = false }: { cancelLoad?: boolean } = {}) =>
  useSWR<WarWeb.CurrentUser>(cancelLoad ? null : basePath);

export const useHasMultipleClients = () => {
  const { data: me } = useMe();
  return me?.clientIds && me?.clientIds.length > 1;
};

export const useShowClients = () => {
  const hasMultipleClients = useHasMultipleClients();
  return isInternalUser() || (isBroker() && hasMultipleClients);
};
