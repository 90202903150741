import { now } from 'moment';
import client from '@/services/client';

export const Roles = {
  broker: 'Broker',
  admin: 'Admin',
  underwriter: 'Underwriter',
  reinsurer: 'Reinsurer',
  businessOperations: 'BusinessOperations'
};

export const saveUserRole = async (role?: string) => {
  const dayInMs = 86400000;
  const expires = now() + dayInMs;
  let userRole;
  if (role) userRole = JSON.stringify({ userRole: role, expires });
  else {
    const { data } = await client.get('me');
    userRole = JSON.stringify({ userRole: data.role, expires });
  }
  localStorage.setItem('role', userRole);
};

export const getUserRole = () => {
  const storedRole = localStorage.getItem('role');
  if (!storedRole) return null;
  const { userRole, expires } = JSON.parse(storedRole);
  const hasExpired = now() > expires;
  if (hasExpired) return null;
  return userRole;
};

export const isExpired = () => {
  const storedRole = localStorage.getItem('role');
  if (!storedRole) return true;
  const { expires } = JSON.parse(storedRole);
  return now() > expires;
};

export const isBroker = () => getUserRole() === Roles.broker;
export const isUnderwriter = () => getUserRole() === Roles.underwriter;
export const isAdmin = () => getUserRole() === Roles.admin;
export const isReinsurer = () => getUserRole() === Roles.reinsurer;
export const isBusinessOperations = () => getUserRole() === Roles.businessOperations;

export const isInternalUser = ({ onlyEditors = false }: { onlyEditors?: boolean } = {}) => {
  if (!onlyEditors) return isUnderwriter() || isAdmin() || isBusinessOperations();
  return isUnderwriter() || isAdmin();
};
